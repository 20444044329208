var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'mobile',
        {
          rules: [
            {
              required: true,
              message: '请输入你的手机号!',
            },
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '不是正确的手机格式!',
            } ],
        } ]),expression:"[\n        'mobile',\n        {\n          rules: [\n            {\n              required: true,\n              message: '请输入你的手机号!',\n            },\n            {\n              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,\n              message: '不是正确的手机格式!',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"手机号","type":"phone"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mobile"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'code',
        {
          rules: [
            {
              required: true,
              message: '请输入验证码!',
            },
            {
              pattern: /^\d{4}$/,
              message: '验证码格式不正确!',
            } ],
        } ]),expression:"[\n        'code',\n        {\n          rules: [\n            {\n              required: true,\n              message: '请输入验证码!',\n            },\n            {\n              pattern: /^\\d{4}$/,\n              message: '验证码格式不正确!',\n            },\n          ],\n        },\n      ]"}],staticClass:"CaptchaIpnt",attrs:{"type":"code","placeholder":"验证码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"code"},slot:"prefix"})],1),_c('div',{staticClass:"authCodeBtn-login"},[(_vm.isSend)?_c('a-button',{on:{"click":_vm.sendSmsCode}},[_vm._v("获取验证码")]):_vm._e(),(!_vm.isSend)?_c('a-button',{attrs:{"disabled":""}},[_vm._v(_vm._s(_vm.count)+"s后可再次发送")]):_vm._e()],1)],1),_c('a-form-item',[_c('a',{staticClass:"login-form-forgot",on:{"click":function($event){return _vm.goForget()}}},[_vm._v(" 忘记密码 ")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleLogin()}}},[_vm._v(" 登录 ")])],1),_c('div',{staticClass:"loginItems"},[_c('span',{staticClass:"resLogin"},[_vm._v("其他方式登录")]),_c('img',{staticClass:"WxLogo",attrs:{"src":require("@/assets/img/WxLogo.png"),"alt":""},on:{"click":function($event){return _vm.goscanCode()}}}),_c('a',{staticClass:"signIn",on:{"click":function($event){return _vm.goRegister()}}},[_vm._v("注册账户")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }