<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangqinggang
 * @Date: 2020-11-24 17:17:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-19 15:23:37
-->
<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    class="login-form"
    id="components-form-demo-normal-login"
    :rules="rules"
  >
    <a-form-model-item prop="username">
      <a-input v-model="form.username" placeholder="用户名/手机号">
        <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="password">
      <a-input v-model="form.password" placeholder="密码" type="password">
        <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item>
      <a-checkbox :checked="checkAgree" @change="handleChange"> 记住密码 </a-checkbox>
      <a class="login-form-forgot" @click="goForget()">忘记密码</a>
      <a-button type="primary" @click="handleLogin()" class="login-form-button"
        >登录</a-button
      >
    </a-form-model-item>
    <div class="loginItems">
      <span class="resLogin">其他方式登录</span>
      <img @click="goscanCode()" class="WxLogo" src="@/assets/img/WxLogo.png" alt />
      <a @click="goRegister()" class="signIn">注册账户</a>
    </div>
  </a-form-model>
</template>

<script>
// import MD5 from "~l/md5.js";
export default {
  data() {
    return {
      checkAgree: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
          },
        ],
      },
      referrer: "",
    };
  },
  methods: {
    //登录
    handleLogin() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //登录请求
          // this.form.password=MD5.md5(this.form.password)
          this.$api.userLogin(this.form).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$ls.set("token", res.data.token);
              this.setCookie("token", res.data.token, 1);
              this.$api.getUserInfo().then((result) => {
                if (result.success) {
                  this.$ls.set("userInfo", result.data);
                  //路由跳转
                  this.$message.success("登录成功");
                  if (this.referrer.includes("https://resource.jsxniu.com")) {
                    window.open("https://resource.jsxniu.com", "_self");
                  } else {
                    this.$router.push("/main");
                  }
                }
              });
              if (this.checkAgree) {
                this.setCookie("username", this.form.username, 7);
                this.setCookie("password", this.form.password, 7);
              } else {
                this.setCookie("username", "");
                this.setCookie("password", "");
              }
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //复选框状态改变
    handleChange(e) {
      this.checkAgree = e.target.checked;
    },
    //跳转忘记密码
    goForget() {
      this.$router.push({ name: "forget" });
    },
    //跳转注册
    goRegister() {
      this.$router.push({ name: "register" });
    },
    //扫码登录
    goscanCode() {
      this.$router.push({ name: "scan" });
    },
    // 获取cookie
    getCookie(key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString()) +
        ";path=/;domain=jsxniu.com";
    },
  },
  created() {
    // 在页面加载时从cookie获取登录信息
    let username = this.getCookie("username");
    let password = this.getCookie("password");
    // 如果存在赋值给表单，并且将记住密码勾选
    if (username) {
      this.form.username = username;
      this.form.password = password;
      this.checkAgree = true;
    }

    let referrer = document.referrer;
    this.referrer = referrer;
    console.log(this.referrer);
  },
};
</script>
<style lang="scss" scope>
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.login-form-forgot {
  font-size: 12px;
  font-family: "PingFang SC";
  font-weight: 400;
  color: #009fe8;
  opacity: 1;
  text-decoration: none !important;
}
.login-form-button {
  width: 348px;
  height: 36px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 20px;
  background: #009fe8;
  border-radius: 18px;
}
</style>
